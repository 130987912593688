.shop .product .product-description {
    height: 180px;
    overflow: hidden;
}

.price .prev {
    color: #989898;
    text-decoration: line-through;
    display: inline-block;
}
.price .current {
    color: #901794;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
}
a, a:link, a:visited, a:active, a:hover {
    cursor: pointer;
}
.hover-class-1:after {
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0;
    transition: all 0.15s ease-out;
}
.hover-class-1:hover:after { opacity: 0.5;}
.products-swiper{margin: 0 -21.25px; position: relative;}
.products-swiper:before, .products-swiper:after{position: absolute; left: 0; top: 0; width: 21.25px; height: 100%; background: #fff; content: ""; z-index: 2;}
.products-swiper:after{right: -3px; left: auto;}
.products-swiper .paddings-container{padding: 0 16px;}
.product-slide-entry{max-width: 330px; height: 420px; overflow: hidden; margin: 0 auto; margin-bottom: 20px;text-align: center;}
.product-image-label{border-radius: 50%; -webkit-border-radius: 50%; position: absolute; z-index: 1;}
.product-image-label span{position: absolute; width: 100%; left: 0; top: 50%; transform: translateY(-50%); -moz-transform: translateY(-50%); -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); font-size: 11px; line-height: 12px; color: #fff; font-weight: 700; text-transform: uppercase; text-align: center;}
.product-image-label.type-1{left: 7px; top: 7px; width: 40px; height: 40px; background: #93b92c;}
.product-image-label.type-2{right: 7px; top: 7px; width: 40px; height: 40px; background: #d41b1b;}
.product-image-label.type-3{left: 50%; top: 50%; margin: -32px 0 0 -32px; width: 65px; height: 65px; background: rgba(64,64,64,0.95);}
.product-slide-entry .product-image{display: block; margin-bottom: 15px; position: relative; overflow: hidden;}
.product-slide-entry .product-image2{display: block; position: relative; overflow: hidden;}
.product-slide-entry img{width: 100%; height: auto; display: block; position: relative; backface-visibility: hidden; -webkit-backface-visibility: hidden;}
.product-slide-entry>img:first-child{margin-bottom: 15px;}
.product-slide-entry.shift-image .product-image:hover img{transform: scale(1) translateX(-100%); -moz-transform: scale(1) translateX(-100%); -webkit-transform: scale(1) translateX(-100%); -ms-transform: scale(1) translateX(-100%);}
.product-slide-entry.shift-image .product-image img{position: absolute; left: 100%; top: 0; width: 100%; height: auto;}
.product-slide-entry.shift-image .product-image img:first-child{position: relative; left: 0;}
.product-slide-entry .product-image .bottom-line{left: 0; bottom: -35px; width: 100%; background: rgba(28,28,28,0.9); text-align: center; font-size: 11px; line-height: 35px; font-weight: 600;}
.product-slide-entry .product-image .bottom-line:hover{left: 0; bottom: -35px; width: 100%; background: #901794; text-align: center; font-size: 11px; line-height: 35px; font-weight: 600;}
.product-slide-entry .product-image:hover .bottom-line{bottom: 0;}
.product-slide-entry .product-image .bottom-line.left-attached{left: -37px; bottom: 0; width: 35px; -webkit-backface-visibility: hidden;}
.product-slide-entry .product-image:hover .bottom-line.left-attached{left: 0;}
.product-image .bottom-line-a{color: #fff;}
.product-image .bottom-line-a:hover{color: #fff; border-color: transparent!important;}
.product-image .bottom-line-a .fa{color: inherit!important;}
.product-image .bottom-line-a.square{width: 35px; border-left: 1px rgba(255, 255, 255, 0.1) solid;}
.product-image .bottom-line.left-attached .bottom-line-a.square{border-left-color: transparent; border-bottom: 1px rgba(255, 255, 255, 0.1) solid;}
.product-image .bottom-line-a .fa{vertical-align: bottom; line-height: 35px; margin-right: 3px; width: 25px;}
.product-image .right-align{float: right;}
.product-image .right-align a{float: left;}
.product-image .left-align{margin-right: 70px;}
.product-image .top-line-a{height: 35px; min-width: 35px; line-height: 35px; padding: 0 10px; background: #fff; position: absolute; top: 10px; cursor: pointer; font-size: 14px; color: #2e2e2e; text-align: center; font-weight: 600;}
.product-image .top-line-a span{font-size: 11px; line-height: 35px; display: inline-block; vertical-align: bottom;}
.product-image .top-line-a .fa{line-height: 35px; display: inline-block; vertical-align: bottom;}
.product-image .top-line-a:hover{background: #2e2e2e; color: #fff;}
.product-image .top-line-a.left{left: -100px;}
.product-image:hover .top-line-a.left{left: 10px;}
.product-image .top-line-a.right{right: -100px;}
.product-image:hover .top-line-a.right{right: 10px;}
.product-slide-entry .tag{font-size: 12px; line-height: 14px; color: #8b8b8b; text-transform: uppercase; display: block; font-weight: 500; margin-bottom: 7px;}
.product-slide-entry .tag:hover{color: #2e2e2e;}
.product-slide-entry .title{display: block; font-size: 14px; line-height: 18px; color: #2e2e2e; font-weight: 600; margin-bottom: 10px;}
.product-slide-entry .title b{font-weight: 700;}
.product-slide-entry .title:hover{color: #03b4ea;}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.inline-label {
    font-size: 13px;
    color: #fff;
    line-height: 19px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    padding: 3px 10px;
    position: relative;
    margin-top: -2px;
    margin-left: 7px;
}

.inline-label.red {
    background: #d14242;
}

.inline-label.purple {
    background: #901794;
}

.product .product-title2 h2, .product .product-title2 h2 a {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.white-popup {
    position: relative;
    background: #FFF;
    padding: 25px;
    width: auto;
    max-width: 900px;
    margin: 0 auto;
}

.post-thumbnail .post-image2 {
    float: left;
    width: 100%;
}
.post-image2 {
    margin-bottom: 20px;
}

.post-image2 img {
  height: auto;
  width: 100%;
}

.blade-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.blade video {
    position: absolute;
    top: -60px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    width: 100%;
    height: auto;
    z-index: 0;
}

.blade-inner {
    display: table;
    vertical-align: middle;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.blade-cell {
    display: table-cell;
    vertical-align: middle;
}

#section1 .blade-bg {
    background-image: url(/images/index01.jpg);
    background-size: cover;
}

#section2 .blade-bg {
    background-image: url(/images/index02.jpg);
    background-size: cover;
}

#section3 .blade-bg {
    background-image: url(/images/index03.jpg);
    background-size: cover;
}

#section5 .blade-bg {
    background-image: url(/images/index05.jpg);
    background-size: cover;
}

#section6 .blade-bg {
    background-image: url(/images/index06.jpg);
    background-size: cover;
}

.even-bg:nth-child(even) {
    background-color: #F6F6F6 !important;
}



@media only screen and (min-width: 1020px) {
  .group-process { display: flex; }
}

@media (max-width: 991px) {
    .only-xxs {
        display: table;
    }
    .blade-bg {
        display: none;
    }
    .blade {
        padding-top: 82%;
    }
    #section1.blade {
        background: url(/images/index01-m.jpg) center top/100% no-repeat;
    }
    #section2.blade {
        background: url(/images/index02-m.jpg) center top/100% no-repeat;
    }
    #section3.blade {
        background: url(/images/index03-m.jpg) center top/100% no-repeat;
    }
    #section4.blade {
        background: url(/images/index04-m.jpg) center top/100% no-repeat;
    }
    #section5.blade {
        background: url(/images/index05-m.jpg) center top/100% no-repeat;
    }
    #section6.blade {
        background: url(/images/index06-m.jpg) center top/100% no-repeat;
    }
    .blade-inner {
        display: block;
        position: static;
        width: auto;
        height: auto;
        text-align: center;
    }
    .text-medium {
        margin: 20px 0;
    }
    .scroll-to {
        bottom: 10px;
    }
    .blade video {
        display: none;
    }
}

.block-process {
     padding-left: 15px;
     padding-right: 15px;
     position: relative;
     z-index: 10;
}

.block-process::before, .block-process::after {
     content: " ";
     display: table;
}

.block-process::after {
     clear: both;
}

.block-process::before, .block-process::after {
     content: " ";
     display: table;
}

.block-process::after {
     clear: both;
}

.block-process .__icon {
     position: relative;
}

.block-process .__icon::before {
     background: #99e3c1 none repeat scroll 0% 0%;
     content: "";
     display: block;
     position: absolute;
     z-index: -1;
}

.block-process:last-child .__icon::before {
     display: none;
}

.block-process.active .__icon .circle-icon::before {
     background-color: #00bfff;
}

.block-process.active .__icon::before, .block-process.active ~ .block-process .__icon::before {
     background-color: #dadada;
}

.block-process.active ~ .block-process .__icon .circle-icon::before {
     background-color: #a2a2a2;
}

@media only screen and (min-width: 1020px) {
  .block-process { text-align: center; }
  .block-process .__title { height: 60px; margin-bottom: 0px; }
  .block-process .__icon { margin: 0px -15px 40px; }
  .block-process .__icon::before { width: 100%; top: 50%; height: 4px; transform: translateY(-50%); }
  .block-process .__icon::before { left: 50%; }
  .group-process.large-icon .block-process .__icon .circle-icon::before { width: 140px; height: 140px; border: 10px solid rgba(255, 255, 255, 0.6); line-height: 120px ! important; font-size: 40px; }
}

@media only screen and (max-width: 1019px) {
  .block-process { padding-left: 100px; padding-bottom: 20px; }
  .block-process .__title { margin-bottom: 10px; }
  .block-process .__icon { position: absolute; width: 70px; height: 100%; left: 15px; top: 0px; }
  .block-process .__icon::before { width: 4px; height: 100%; left: 50%; transform: translateX(-50%); }
}

.block-process-2 {
     position: relative;
}

.block-process-2 .cell-vertical-wrapper {
     height: 320px;
}

.block-process-2 .__number {
     bottom: 0px;
     color: #333333;
     font-size: 200px;
     height: 200px;
     left: 6px;
     line-height: 1;
     margin-bottom: auto;
     margin-top: auto;
     opacity: 0.04;
     position: absolute;
     top: -20px;
}

.block-process-2 .__block {
     margin-left: auto;
     margin-right: auto;
     padding-bottom: 40px;
     padding-top: 40px;
}

.block-process-2 .__block > * {
     letter-spacing: 0.05em;
}

.block-process-2 .__block .__title {
     font-size: 14px;
     margin-bottom: 10px;
}

.block-process-2 .__block p.__content {
     margin-bottom: 0px;
}

.block-process-2.size-small .cell-vertical-wrapper {
     height: 240px;
}

.block-process-2.size-small .__block > * {
     letter-spacing: 0px;
}

.block-process-2.size-small .__block .__title {
     font-size: 18px;
     margin-bottom: 5px;
}

@media only screen and (min-width: 1020px) {
  .block-process-2 .__block { max-width: 320px; }
}

@media only screen and (max-width: 1019px) {
  .block-process-2 .__block { max-width: 720px; }
}

/* Icon-box-list */
.group-icon-box-list-right-slider {
  position: relative;
}
.group-icon-box-list-right-slider .__block-wrapper-1,
.group-icon-box-list-right-slider .__block-wrapper-2 {
  padding-right: 15px;
  padding-left: 15px;
}
.group-icon-box-list-right-slider .__block-wrapper-1 .__block,
.group-icon-box-list-right-slider .__block-wrapper-2 .__block {
  margin-right: auto;
  margin-left: auto;
}
.group-icon-box-list-right-slider .__block-wrapper-1 {
  padding-top: 100px;
  padding-bottom: 60px;
}
.group-icon-box-list-right-slider .__block-wrapper-1 .__block .hr-header {
  margin-bottom: 30px;
}
.group-icon-box-list-right-slider .__block-wrapper-2 {
  position: relative;
}
.group-icon-box-list-right-slider .__block-wrapper-2 .cell-vertical-wrapper {
  table-layout: fixed;
}
.group-icon-box-list-right-slider .__block-wrapper-2 .__block {
  max-width: 640px;
}
@media only screen and (min-width: 768px ) {
    .only-xxs {
        display: none !important;
    }
  .group-icon-box-list-right-slider .__block-wrapper-1 .__block {
    width: 720px;
  }
}
@media only screen and (min-width: 1020px ) {
  .group-icon-box-list-right-slider .__block-wrapper-1 .__block {
    width: 960px;
  }
}
@media only screen and (min-width: 1230px ) {
  .group-icon-box-list-right-slider .__block-wrapper-1,
  .group-icon-box-list-right-slider .__block-wrapper-2 {
    width: 50%;
    padding-right: 30px;
    padding-left: 30px;
  }
  .group-icon-box-list-right-slider .__block-wrapper-1 .__block {
    max-width: 680px;
  }
  .group-icon-box-list-right-slider .__block-wrapper-2 {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
}
@media only screen and (max-width: 1229px ) {
  .group-icon-box-list-right-slider .__block-wrapper-2 {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
.group-icon-box-list-side-image {
  position: relative;
}
.group-icon-box-list-side-image:before,
.group-icon-box-list-side-image:after {
  content: " ";
  display: table;
}
.group-icon-box-list-side-image:after {
  clear: both;
}
.group-icon-box-list-side-image:before,
.group-icon-box-list-side-image:after {
  content: " ";
  display: table;
}
.group-icon-box-list-side-image:after {
  clear: both;
}
.group-icon-box-list-side-image .__block-wrapper-2 {
  padding-top: 100px;
  padding-bottom: 75px;
  padding-right: 15px;
  padding-left: 15px;
}
.group-icon-box-list-side-image .__block-wrapper-2 .__block {
  margin-right: auto;
  margin-left: auto;
}
.group-icon-box-list-side-image .__block-wrapper-2 .__block > .__title {
  margin-bottom: 20px;
}
.group-icon-box-list-side-image .__block-wrapper-2 .__block > .__caption {
  margin-bottom: 50px;
}
.group-icon-box-list-side-image .__block-wrapper-2 .__block .block-icon-box-left-icon {
  margin-bottom: 25px;
}
.group-icon-box-list-side-image .__block-wrapper-2 .__block .block-icon-box-left-icon .__right-side .__heading {
  padding-top: 5px;
  margin-bottom: 8px;
}
@media only screen and (min-width: 768px ) {
  .group-icon-box-list-side-image .__block-wrapper-2 .__block {
    width: 720px;
  }
}
@media only screen and (min-width: 1020px ) {
  .group-icon-box-list-side-image .__block-wrapper-2 .__block {
    width: 960px;
  }
}
@media only screen and (min-width: 1230px ) {
  .group-icon-box-list-side-image .__block-wrapper-1,
  .group-icon-box-list-side-image .__block-wrapper-2 {
    width: 50%;
  }
  .group-icon-box-list-side-image .__block-wrapper-2 {
    padding-right: 30px;
    padding-left: 30px;
    float: right;
  }
  .group-icon-box-list-side-image .__block-wrapper-2 .__block {
    max-width: 700px;
  }
  .group-icon-box-list-side-image .__block-wrapper-1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
@media only screen and (max-width: 1229px ) {
  .group-icon-box-list-side-image .__block-wrapper-1 {
    position: relative;
    min-height: 500px;
  }
}


.static-background {
  background-position: 50% 50%;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

@media screen and (max-width: 767px) {
  .hr-title.hr-long {
    width: 100%!important;
}
  }

